<template>
  <v-row >
    <v-col 
      cols="12"
      sm="6"
      md="4"
      lg="3" 
      xl="2"
      v-for="item in items" :key="item.id"
    >
      <slot name="item" :item="item" />
    </v-col>

    <div v-if="items.length" v-observe-visibility="handleScrolledToBottom"></div>
  </v-row>
</template>

<script>
export default {
  name: 'InfiniteScroll',
  props: {
    items: {
      required: true,
      type: Array
    },
    totalPages: Number
  },
  data: () => ({
    page: 1
  }),
  watch: {
    totalPages () {
      this.page = 1
    }
  },
  methods: {
    handleScrolledToBottom (isVisible) {
      if (!isVisible) { return }

      this.page++

      this.$emit('refetch', this.page)
    }
  }
}
</script>