<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="550">
      <v-card elevation="2">
        <v-container>
          <v-progress-linear indeterminate color="primary"></v-progress-linear
        ></v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    dialog: true
  })
}
</script>
