<template>
  <v-hover
    v-slot:default="{ hover }"
    open-delay="200"
  >
    <v-card
      :elevation="hover ? 16 : 2"
      max-width="480"
      max-height="480"
    >
      <v-card flat fluid >
       
        <div style="height:150px">
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            :src="test.image
              ? `${apiUrl}/images/candidatecontent/${test.image}` : `/teste.jpeg`"
            height="150px"
          >
            <v-system-bar
              window
              flat
              color="rgba(0, 0, 0, 0)"
              dense
            >
              <v-menu
                bottom
                left
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    @click.prevent="$emit('edit', test.id)"
                  >
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="$emit('delete', test.id)"
                  >
                    <v-list-item-title >Remover</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
             

              <v-spacer></v-spacer>

              <!-- <v-chip
                dark
                small
                label
                class="text-capitalize"
              >
                {{ test.position }}
              </v-chip> -->
            </v-system-bar>
          </v-img>
        </div>

        <v-card-text>
           <v-btn small text color="primary"> {{ test.position }}</v-btn>
         <!-- <pre>{{test.image}}</pre> -->
          <div
            v-if="test.title.length > 17"
            class="text-subtitle-1 black--text py-1"
          >
          <strong>  {{ test.title }} </strong>
          
          </div>
          <div v-else class="text-subtitle-1 black--text py-1">
             <strong>  {{ test.title }} </strong> <br /><br />
          </div>
       
          <div
            v-if="test.description.length > 90"
            class="black--text text-justify"
            style="font-size: 14px; height:50px"
            v-html="test.description ? test.description.substring(0, 60) + '...' : ''"
          >
          
          </div>
          <div
            v-else
            class="black--text text-justify"
            style="font-size: 14px; height:50px"
            v-html="test.description ? test.description : ''"
          >
          
          </div> 
        </v-card-text>
      </v-card>
      <v-card-actions>
       
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from '@/api'
import { mapGetters } from 'vuex'
import formatCurrencyMixins from '@/mixins/format-currency'
export default {
  name: 'TestCard',
  props: {
    test: Object,
  },
  mixins: [
    formatCurrencyMixins
  ],
  data: () => ({
    apiUrl: API_URL,
    uri: API_URL + "/images/candidatecontent/",
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
   
  }
}
</script>